import React from "react"
import "./rohanshow.css"

export default function RohanShow() {
  // document.title = "The Rohan Show - Coming soon :)"
  return (
    <div>
      <img id="rohanshow" src="/assets/Rohan-Show.svg" alt="The Rohan Show" />
    </div>
  )
}
